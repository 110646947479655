import { template as template_563c86d51fef4f85b1fb67a5b35d6bb5 } from "@ember/template-compiler";
const FKControlMenuContainer = template_563c86d51fef4f85b1fb67a5b35d6bb5(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
