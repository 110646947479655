import { template as template_5054cf200a6d4139b1105990fcd06296 } from "@ember/template-compiler";
const FKLabel = template_5054cf200a6d4139b1105990fcd06296(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
